import { COOKIES_CONFIG } from '@belong/common';
import axios from 'axios';
import { parseCookies, setCookie } from 'nookies';
import { refreshToken as refreshTokenAction } from 'store/redux/user/actions';
import { MODALS } from '../../../containercomponents/Modals/modal.consts';
import { hideModal, showModal } from '../modals/actions';

/* May be move this to reducer */
const activeRequests = {};

const handleReducerError = (_e) => {
  console.error(_e);
};

export default function clientMiddleware(helpers, history) {
  return ({ dispatch, getState }) =>
    (next) =>
    async (action) => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      const { promise, types, auth, handleError, cancel, ...rest } = action;

      if (!promise) {
        try {
          return next(action);
        } catch (error) {
          handleReducerError(error);
        }
      }

      if (auth) {
        const { client } = helpers;

        if (!client.getJwtToken()) {
          const existingUserData = getState().user.user;

          return new Promise((resolve) => {
            dispatch(
              showModal(MODALS.LOGIN, {
                currentScreen: action.loginModalScreen ? action.loginModalScreen : null,
                redirectToHomeOnHide: !!action.redirectToHomeOnHide,
                screenProps: {
                  ...existingUserData,
                  emailAddress: existingUserData?.email,
                },
                onSucessfulLogin: async () => {
                  const response = await dispatch(action);
                  resolve(response);
                },
              })
            );
          });
        }
      }

      const [REQUEST, SUCCESS, FAILURE] = types || [];

      if (REQUEST) {
        try {
          next({ ...rest, type: REQUEST });
        } catch (error) {
          handleReducerError(error);
        }
      }

      let actionPromise;

      if (cancel) {
        if (activeRequests[REQUEST]) {
          activeRequests[REQUEST].cancel();
        }
        const source = axios.CancelToken.source();
        activeRequests[REQUEST] = source;
        actionPromise = promise(helpers, source);
      } else {
        actionPromise = promise(helpers);
      }

      try {
        const result = await actionPromise;

        if (SUCCESS) {
          delete activeRequests[REQUEST];

          try {
            next({ ...rest, result, type: SUCCESS });
          } catch (error) {
            handleReducerError(error);
          }
        }

        return result;
      } catch (err) {
        /**
         * This should only happen when navigating within routes defined in belong-web.
         * If any of the routes involved belongs to belong-next or there is page reload, its middleware will perform the token rotation.
         */
        if (err?.response?.status === 401) {
          const cookies = parseCookies();
          const token = cookies[COOKIES_CONFIG.JWT_TOKEN.name];
          const refreshToken = cookies[COOKIES_CONFIG.REFRESH_TOKEN.name];

          try {
            /**
             * Keep in mind that for pages when multiple request are fired upon arrival, we may end up having a single success and several
             * failures on this endpoint.
             * There is for sure a way to prevent that from happening but it is not worth the effort on a legacy stack.
             */
            const response = await dispatch(refreshTokenAction({ token, refreshToken }));

            const { client } = helpers;
            client.setJwtToken(response.token);

            setCookie(null, COOKIES_CONFIG.JWT_TOKEN.name, response.token, COOKIES_CONFIG.JWT_TOKEN.options);
            setCookie(
              null,
              COOKIES_CONFIG.REFRESH_TOKEN.name,
              response.refreshToken,
              COOKIES_CONFIG.REFRESH_TOKEN.options
            );

            /**
             * We should retry every single request that failed due to 401 by this point.
             * However, this middleware is already overly complicated so going for the easy fix.
             * In the end, all the authentication/authorization logic will live in belong-next and this will be removed after all.
             */
            history.go(0);
          } catch {
            const backTo = `${history.location.pathname}${history.location.search}`;

            const loginURL = new URL('/auth/login', window.location.origin);
            loginURL.searchParams.set('backTo', backTo);

            /**
             * The login page is on Next.js, that is why we cannot use history.push().
             */
            window.location.replace(loginURL.toString());
          }
        }

        // Had to move this here from apiClient interceptor because err.response.data can be an object or an array,
        const isError400s = err?.response?.status >= 400 && err?.response?.status < 500;
        const error = err?.response ? err.response.data : err;

        if (FAILURE) {
          try {
            if (!axios.isCancel(err)) {
              next({ ...rest, error, type: FAILURE });
            }
          } catch (reducerError) {
            handleReducerError(reducerError);
          }
        }

        // https://github.com/axios/axios#handling-errors
        const context = {};

        if (err?.response) {
          context.validation_error = String(isError400s);
          context.error_object = error;
        } else if (err?.request) {
          context.server_error = 'true';
          context.request = JSON.stringify(err.request);
        } else {
          context.request_error = 'true';
        }

        if (handleError) {
          dispatch(
            showModal(MODALS.GENERIC_ERROR, {
              onCtaClick: async () => {
                dispatch(hideModal());
              },
            })
          );
        }

        return Promise.reject(error);
      }
    };
}
